import { CreateControllerFn } from '@wix/yoshi-flow-editor';
import { createWidgetPluginExports } from '@wix/members-area-widget-plugin-lib/viewer';

import { getControllerContext, pageReady } from './controller-utils';

const createController: CreateControllerFn = async (controllerProps) => {
  const context = getControllerContext(controllerProps);

  const pageReadyWrapper = () => {
    return pageReady(context);
  };
  return {
    async pageReady() {
      const isRendered = context.widgetPluginService.getIsRendered();

      if (!isRendered) {
        context.setProps({ isRendered });
        return Promise.resolve();
      }

      await pageReadyWrapper();
    },
    updateConfig(_, { publicData }) {
      context.settingsListener.notify(publicData.COMPONENT || {});
    },
    exports: () => {
      return createWidgetPluginExports(
        context.widgetPluginService,
        pageReadyWrapper,
      );
    },
  };
};

export default createController;
